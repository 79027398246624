import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import top from "../assets/images/teeth-top.svg";
import topDark from "../assets/images/teeth-top-dark.svg";
import StormLogoPng from "../assets/images/logo-storm-v02.png";
import { ReactComponent as MetroLogo } from "../assets/images/metro-logo.svg";

const Wrap = styled.div`
  width: 100%;
  height: ${props => props.offset}px;;
  background-color: #592B8C;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  pointer-events: none;
  margin-bottom: ${props => props.offset}px;
`

const TeethTop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: calc(100% - ${props => props.offset * 2}px);
  background: url(${top});
  background-size: 100%;
  background-position: bottom;
`;

const TeethTopDark = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: calc(100% - ${props => props.offset * 2}px);
  background: url(${topDark});
  background-size: 100%;
  background-position: bottom;
`;

const Metro = styled(MetroLogo)`
  width: 10%;
  z-index: 1;
  margin-right: 7%;
  margin-top: 4%;
`;

const Teeth = ({ dark }) => {

  const getWidth = () => window.innerWidth 
    || document.documentElement.clientWidth
    || document.body.clientWidth;

  const [teethOffsetHeight, setTeethOffsetHeight] = useState(getWidth() * 0.15);

  useEffect(() => {
    // change width from the state object
    const resizeListener = () => setTeethOffsetHeight(getWidth() * 0.15);

    window.addEventListener('resize', resizeListener);
    // clean up function
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  return (
    <Wrap offset={teethOffsetHeight}>
      {dark ? <TeethTopDark offset={teethOffsetHeight} /> : <TeethTop offset={teethOffsetHeight} />} 
      <img 
        src={StormLogoPng}
        style={{
          width: "11%",
          zIndex: 1,
          marginLeft: "7%",
          marginTop: "4%",
        }} 
      />
      <Metro />
    </Wrap>
  );
};

Teeth.propTypes = {
  dark: PropTypes.bool
};

Teeth.defaultProps = {
  dark: false
};

export default Teeth;
