import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import AnimatedRoutes from "./containers/AnimatedRoutes";

import * as types from "./redux/types";

import settings from './constants/settings';

const Container = styled.div`
  overflow: hidden;
  height: 100%;
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: settings.timeout,
    };
  }

  render() {
    return (
        <Container>
          <AnimatedRoutes />
        </Container>
    );
  }
}

App.propTypes = {
  resetRedux: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetRedux: () => ({
        type: types.RESET_REDUX
      })
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withRouter(App));
