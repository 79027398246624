import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrap = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 48px;
`;

const Foreground = styled.div`
  width:100%;
  box-sizing: border-box;
  transform: rotate(-3.5deg);
`;


const ImageWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  transform: rotate(-3.5deg);
`;

const Image = styled.img`
  width: 50%;
  border: 8px solid white;
  box-shadow: -5px 10px 0px rgba(0, 22, 65, 0.5);
`;

const PolaroidFanLanding = (props) => {
  return (
    <Wrap>
      <Foreground>
        <ImageWrap>
          <Image src={props.image || 'loading.png'} alt="Your Melbourne Storm Photobooth Image" />
        </ImageWrap>
      </Foreground>
    </Wrap>
  );
};

PolaroidFanLanding.propTypes = {
  image: PropTypes.string,
};

export default PolaroidFanLanding;
