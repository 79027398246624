
export const RESET_REDUX = "RESET_REDUX";



export const FETCH_IMAGE = "FETCH_IMAGE";
export const FETCH_IMAGE_SUCCESS = "FETCH_IMAGE_SUCCESS";
export const FETCH_IMAGE_ERROR = "FETCH_IMAGE_ERROR";




export const POST_EMAIL = "POST_EMAIL";
export const POST_EMAIL_SUCCESS = "POST_EMAIL_SUCCESS";
export const POST_EMAIL_ERROR = "POST_EMAIL_ERROR";