import styled from "styled-components";
import PropTypes from "prop-types";

const StandoutText = styled.span`
  font-family: SuzukiFunt-Heavy;
  font-size: ${props => props.fontSize};
  color: ${props => props.fontColor};
  text-shadow: ${props => props.textShadow};
  padding: ${props => props.padding};
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000000;
`;

StandoutText.propTypes = {
  fontSize: PropTypes.string,
  fontColor: PropTypes.string,
  textShadow: PropTypes.string,
  padding: PropTypes.string,
};

StandoutText.defaultProps = {
  fontSize: "48px",
  fontColor: "#592b8c",
  textShadow: "-2px 4px 0px #000000",
  padding: "0px 6px 2px 4px",
};

export default StandoutText;
