import React, { useContext } from "react";
import PropTypes from "prop-types";
import { animated, useTransition } from "react-spring";
import { Switch, Route, Redirect, __RouterContext } from "react-router";
import { useSelector, useDispatch  } from "react-redux";


import ViewFanLanding from "./ViewFanLanding";
import NoID from "./NoID";
import Terms from "./Terms";


const AnimatedRoutes = React.forwardRef(({}, ref) => {
  const { location } = useContext(__RouterContext);

  

  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0 },
    enter: item => async (next, cancel) => {
      await new Promise(resolve => setTimeout(resolve, 800));
      await next({ opacity: 1 });
    },
    leave: { opacity: 0, delay: 1000 }
  });

  return transitions.map(({ item, props, key }) => (
    <animated.div key={key} style={props}>
      <Switch location={item}>
      <Route
          exact
          path='/'
          render={props => <NoID {...props} />}
        />
      <Route
        exact
        path='/terms'
        render={props => <Terms {...props} />}
      />
        <Route exact path="/:photoId" render={(props) => (
            <ViewFanLanding photoId={props.match.params.photoId}/>
        )} />
        <Route render={() => <Redirect to='/' />} />
      </Switch>
    </animated.div>
  ));
});

AnimatedRoutes.propTypes = {};

export default AnimatedRoutes;
