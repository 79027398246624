import * as types from "./types";

const initialState = {
  loading: false,
  image: null,
  qrCode: null,
  photoId: null,
  photoURL: null
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case types.FETCH_IMAGE:
      return {
        ...state,
        photoId: action.photoId,
        loading: true
      };

    case types.FETCH_IMAGE_SUCCESS:
      return {
        ...state,
        photoURL: action.photoURL,
        loading: false
      };

    case types.FETCH_IMAGE_ERROR:
      return {
        ...state,
        loading: false
      };


      case types.POST_EMAIL:
        return {
          ...state,
          loading: true
        };

      case types.POST_EMAIL_SUCCESS:
        return {
          ...state,
          loading: true
        };

      case types.POST_EMAIL_ERROR:
        return {
          ...state,
          loading: true
        };

    case types.RESET_REDUX:
      return initialState;

    default:
      return state;
  }
};

export default appReducer;

export const selectPhotoID = state => state.app.photoId;
export const selectImage = state => state.app.photoURL;
export const selectLoading = state => state.app.loading;
