import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import StandoutText from "./StandoutText";

const Button = styled.div`
  font-family: TTSupermolot-BlackItalic;
  font-size: 24px;
  border: 6px solid black;
  padding: 10px;
  text-transform: uppercase;
  background-color: #ffffff;
  text-align: center;
  width: 80px;
  margin-top: 20px;
  z-index: 1;
`;

const Wrap = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0
  right: 0;
  display: flex;
  height: 100%;
  width: 100%;
  font-family: TTSupermolot-BlackItalic;
  font-size: 36px;
  text-transform: uppercase;
  color: #ffffff;
  flex-direction: column;
  text-align: center;
  padding: 16px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,22,65,0.8);
  z-index: 1;

  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => props.visible ? 1 : 0};
  pointer-events: ${props => props.visible ? 'all' : 'none'};
`;


const ModalOverlay = ({ visible, setVisible }) => {
  return (
    <Wrap visible={visible}>
      Photo sent!
      <Button onClick={() => setVisible(false)}>
        <StandoutText fontSize="30px" fontColor="#001641" padding="0px 10px 0px 0px">OK</StandoutText>
      </Button>
    </Wrap>
  );
};

ModalOverlay.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};

export default ModalOverlay;