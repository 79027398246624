import * as types from "./types";
import settings from '../constants/settings';
import axios from "axios";

const baseUrl = settings.api_base;

export const fetchImage = photoId => {

  console.log(`DISPATCHING FETCH_IMAGE (${settings.activation_id}) (${photoId})`)
  console.log(photoId)
  return dispatch => {
    
    dispatch({ type: types.FETCH_IMAGE, photoId, });

    return axios
      .get(
        `${baseUrl}/serve?id=${photoId}&aid=${settings.activation_id}`,
        {
          headers: { "Content-Type": "application/json"  }
        }
      )
      .then(result => {
        dispatch({ type: types.FETCH_IMAGE_SUCCESS, photoURL: result.data.photoURL });
      })
      .catch(error => dispatch({ type: types.FETCH_IMAGE_ERROR }));
  };
};



export const postEmail = ({ email, fname, photoId }) => {

  console.log(`DISPATCHING POST EMAIL (${settings.activation_id}) (${email}) (${fname}) (${photoId})`);

  return dispatch => {
    // Set loading state
    dispatch({ type: types.POST_EMAIL });



    return axios({
      "method": "POST",
      "url": `${baseUrl}/claim`,
      "headers": {
        "Content-Type": "application/json; charset=utf-8"
      },
      "data": { email, photoId, fname, aid: settings.activation_id }
    }).then(result => {
      console.log(`POST_EMAIL_SUCCESS ${result}`);
      dispatch({ type: types.POST_EMAIL_SUCCESS });
    })
    .catch(error => dispatch({ type: types.POST_EMAIL_ERROR }));


  };
};


