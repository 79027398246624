import React, { Text, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import PageContainer from "../components/PageContainer";

import * as types from "../redux/types";

const NoID = ({ resumeTimer }) => {



  return (
    <PageContainer backgroundColor='#B62DC0' paddingTop={300}>
      
      
    </PageContainer>
  );
};

NoID.propTypes = {
  
};

export default NoID;
