import React from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import styled from "styled-components";
import StandoutText from "./StandoutText";
import { useSelector, useDispatch  } from "react-redux";

import { selectPhotoID, } from "../redux/reducers";
import { postEmail } from "../redux/actions";

const Wrap = styled.div`
  position: relative;
  width: 75%;
  min-width: 200px;
  margin-bottom: 40px;
`;

const Input = styled.input`
  box-sizing: border-box;
  font-family: TTSupermolot-BlackItalic;
  font-size: 14px;
  border: 2px solid black;
  padding: 12px;
  text-transform: uppercase;
  background-color: #ffffff;
  height: 42px;
  width: 100%;
  ::placeholder {
    color: #C7C9C8;
  }
`;

const Button = styled.button`
  border: 2px solid black;
  background-color: #ffffff;
  cursor: pointer;
  text-transform: uppercase;
  height: 42px;
  width: 100%;
`;

const ErrorFrame = styled.div`
  font-family: TTSupermolot-BlackItalic;
  font-size: 12px;
  font-color:  #FF0000;
  width: 100%;
  text-align: left;
  padding: 6px;
  text-transform: uppercase;
`;


const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;


const CheckboxWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 100%;
`;

const TermsCheckbox = styled.input`
 width: 30px;
 height: 30px;
 margin-right: 10px;
 box-sizing: border-box;

`

const Text = styled.h1`
font-size: 11px;
color: #ffffff;
letter-spacing: 1px;
box-sizing: border-box;
width:100%;
padding-top:5px;
`

const Link = styled.a`
font-size: 11px;
color: #ffffff;
text-decoration:underline;
letter-spacing: 1px;
box-sizing: border-box;
width:100%;
padding-top:5px;
`

Wrap.propTypes = {
  height: PropTypes.string,
  position: PropTypes.string,
  width: PropTypes.string,
  display: PropTypes.string,
  justifyContent: PropTypes.string,
  padding: PropTypes.string,
}

Wrap.defaultProps = {
  position: "relative",
  width: "100%",
  display: "flex",
  justifyContent: "center",
}

export default function EmailHookForm({ onFormSubmit }) {

  const dispatch = useDispatch();
  const photoId = useSelector(state => selectPhotoID(state));


  const [checkedOne, setCheckedOne] = React.useState(false);
 


  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = data => {
    dispatch(postEmail({...data, photoId}));
    onFormSubmit()
    reset()
  }


  return (
    <Wrap>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrap>
          <Input placeholder="First Name" {...register("fname", { required: true, maxLength: 40 })} />
          <ErrorFrame> 
            {errors.fname?.type === 'required' ? "First name is required" : ""}
          </ErrorFrame>
          <Input placeholder="Email" {...register("email", { required: true, pattern: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/i })} />
          <ErrorFrame> 
            {errors.email?.type === 'required' ? "Email is required" : 
            errors.email?.type === 'pattern' ? "Enter a valid email address" : ""}
          </ErrorFrame>

          <CheckboxWrap>
          < TermsCheckbox type="checkbox" value="I Agree" {...register("terms", { required: true })} />
            <Text>I agree to the <Link href="./terms" target="_blank" title="Terms and Conditions">Terms and Conditions</Link></Text>
          

          </CheckboxWrap>
          <ErrorFrame> 
            {errors.terms?.type === 'required' ? "You must agree to the Terms and Conditions" : "" }
          </ErrorFrame>

          <Button type="submit" value="Submit">
            <StandoutText fontSize="30px" fontColor="#fff">Send!</StandoutText>
          </Button>
        </InputWrap>
      </form>
    </Wrap>
  );
};

const Checkbox = ({ label, value, onChange }) => {
  return (
    <label>
      <input type="checkbox" checked={value} onChange={onChange} />
      {label}
    </label>
  );
};

EmailHookForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};