import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";


// Needed for animations
const Wrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${props => props.backgroundColor};
  overflow: hidden;
`;

const PageContainer = ({
  children,
  backgroundColor,
  paddingTop,
  paddingBottom,
  onClick
}) => {

  return (
    <Wrap>
      <Container
        backgroundColor={backgroundColor}
        onClick={onClick}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
      >
        {children}
      </Container>
    </Wrap>
  );
};

export default PageContainer;

PageContainer.propTypes = {
  backgroundColor: PropTypes.string,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
};

PageContainer.defaultProps = {
  backgroundColor: "transparent",
  paddingTop: 0,
  paddingBottom: 0,
};
