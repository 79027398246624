import React, { Text, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import PageContainer from "../components/PageContainer";

import * as types from "../redux/types";


const Wrap = styled.div`
  position: relative;
  width: 100%;
  margin: 0px auto;
  max-width: 1000px;
  height: 100%;
  text-align:justify;
  box-sizing: border-box;
  padding:20px;
  background-color: #592B8C;
`;


const Heading = styled.h1`
font-size: 14px;
color: #ffffff;
letter-spacing: 1px;
box-sizing: border-box;
padding-top:5px;
`


const BodyText = styled.span`
font-size: 11px;
color: #ffffff;
letter-spacing: 1px;
box-sizing: border-box;
padding-top:5px;
font-family: Verdana;
text-transform: none;
width: 100%;
display:block;
padding:5px;
`


const Link = styled.a`
font-size: 11px;
color: #ffffff;
text-decoration:underline;
letter-spacing: 1px;
box-sizing: border-box;
width:100%;
overflow-wrap: anywhere;
word-wrap: break-word;
`


const Terms = () => {



  return (

      <Wrap>
        <Heading>Terms and conditions</Heading>

        <BodyText>
        I warrant and represent to Metro Trains Melbourne Pty Ltd (Metro) that I am over the age of 18 years old. I hereby acknowledge and grant permission to Metro, Melbourne Storm and/or their affiliates and/or agents to:
        </BodyText>

        <BodyText>
          1. Contact me for marketing purposes.
        </BodyText>

        <BodyText>
          2.	Collect, use and transfer my personal information in accordance with the terms of Metro and Melbourne Storm's privacy policy. Copies of these privacy policies are available at: 
        </BodyText>
        
        <BodyText>
          <Link href="https://www.metrotrains.com.au/privacy/" target="_blank">https://www.metrotrains.com.au/privacy/</Link>
        </BodyText>

        <BodyText>
          <Link href="https://www.melbournestorm.com.au/privacy-policy/" target="_blank">https://www.melbournestorm.com.au/privacy-policy/</Link>
        </BodyText>

        <BodyText>
          3.	Use my image, as captured in this photo booth (and any copies or amendments), in their absolute discretion and not limited to use on social media and promotional campaigns. 
        </BodyText>

        <BodyText>
        I also acknowledge and agree that:
        </BodyText>

        <BodyText>
        4.	All images captured in this photo booth (and any copies or amendments) and intellectual property relating to those images are owned by Metro and/or Melbourne Storm and I have no claim to ownership of those images or intellectual property now or in the future. 
        </BodyText>

        <BodyText>
        5.	I am not entitled to any compensation or remuneration now or in the future, for the use of my image.
        </BodyText>

      </Wrap>
      
  );
};

Terms.propTypes = {
  
};

export default Terms;
