import React, { useState, useEffect } from "react";
import { useSelector, useDispatch  } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import PageContainer from "../components/PageContainer";
import EmailHookForm from "../components/EmailHookForm";
import PolaroidFanLanding from "../components/PolaroidFanLanding";
import { ReactComponent as Instagram } from "../assets/images/instagram-white.svg";
import { ReactComponent as Facebook } from "../assets/images/facebook-white.svg";
import { ReactComponent as Twitter } from "../assets/images/twitter-white.svg";

import { selectImage, } from "../redux/reducers";
import { fetchImage } from "../redux/actions";
import Teeth from "../components/Teeth";
import ModalOverlay from "../components/ModalOverlay";
import StandoutText from "../components/StandoutText";

const ViewFanLanding = ( {photoId} ) => {
  
  const photoURL = useSelector(state => selectImage(state));
  const dispatch = useDispatch();
  dispatch(fetchImage(photoId));

  const Text = styled.h1`
    font-size: 18px;
    color: #ffffff;
    text-align: left;
    margin-left: 48px;
    letter-spacing: 1px;
    width: 100%;
  `

  const FormTitle = styled.h1`
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    letter-spacing: 1px;
    width: 100%;
  `
  const Title = styled.div`
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    padding-right: 32px;
    vertical-align: middle;
  `

  const Disclaimer = styled.h2`
    font-family: "OpenSans-Regular";
    font-weight: 400;
    font-size: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #ffffff;
    text-align: center;
    width:100%;
  `

  const SocialWrap = styled.div`
    width: 40%;
    display: flex;
    justify-content: space-between;
  `;

  const [overlayVisible, setOverlayVisible] = useState(false)

  const onEmailSubmit = () => {
    setOverlayVisible(true)
  }

  return (
    <PageContainer backgroundColor='#592B8C'>
      <Teeth dark />

      <Title>
        <Text>
          Here's your
        </Text>
        <StandoutText fontSize="30px" fontColor="#FFF203" >Photo!</StandoutText>
      </Title>

      <PolaroidFanLanding image={photoURL} />

      <FormTitle>
        Send it to your email
      </FormTitle>
      <EmailHookForm onFormSubmit={onEmailSubmit} />

      {/* <Text>
        Share it on social
      </Text>

      <SocialWrap>
        <a href="https://www.facebook.com/MelbStormRLC/">
          <Facebook style={{width: 25, height: 25, margin: 8 }} />
        </a>
        <a href="https://twitter.com/storm">
          <Twitter style={{width: 25, height: 25, margin: 8 }} />
        </a>
        <a href="https://www.instagram.com/storm/">
          <Instagram style={{width: 25, height: 25, margin: 8 }} />
        </a>
      </SocialWrap>

      <Disclaimer >
        Images will be deleted after 30 days
      </Disclaimer> */}

      <ModalOverlay visible={overlayVisible} setVisible={setOverlayVisible}/>
    </PageContainer>
  );
};


ViewFanLanding.propTypes = {
  photoId: PropTypes.string.isRequired
};

export default ViewFanLanding;
